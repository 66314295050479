import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import './concept.css';
import Img from '../../images/concept.jpg';
import Header from '../Header/Header';
import ImgDetails from '../ImgDetails/ImgDetails';
import { OrderContext } from '../../contexts/OrderContext';
import Footer from '../Footer/Footer';
import Parser from 'html-react-parser';
const Concept = (props) => {
    const [img, setImg] = useState(Img);
    const { activeOrder } = useContext(OrderContext);
    const checkUrl = useLocation().state;
    const data = useLocation().state.data[0];
    useEffect(() => {
        if (checkUrl === null) {
            window.location.href = '/directaccess';
        }
    }, []);
    window.scrollTo(0, 0);
    return (
        checkUrl !== null && <section className="main-section concept-section">
            <div className="main-container">
                <Header title='Notre Concept' target={'/'} />
                <ImgDetails img={data.image} content={Parser(data.description)} />
            </div>
            <Footer />
        </section>
    )
}

export default Concept;