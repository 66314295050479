import React from 'react';
import { Link } from 'react-router-dom';
import './producteur.css';
const Producteur = (props) => {
    const { id, name, image } = props.producteur;
    return (
        <Link to={'/producteur/'+name.replace(/\s+/g, '-').toLowerCase()} className="producteur" state={{ person: props.producteur }}>
            <img src={`https://admin.confluencenamur.be/${image}`} alt="" className="person-img" />
            <div className="name">
                {name}
            </div>
        </Link>
    )
}
export default Producteur;