import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import Header from "../Header/Header";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import './carte.css';
// import required modules
import { Navigation } from "swiper";
import ImgInfos from "../ImgInfos/ImgInfos";
import { OrderContext } from '../../contexts/OrderContext';
import OrderBtn from "../OrderBtn/OrderBtn";
import Footer from "../Footer/Footer";
import Loading from "../Loading/Loading";
const Carte = (props) => {
    const [mainElems, setMainElems] = useState([{
        id: 3,
        item: "A grignoter",
        activate: false
    },
    {
        id: 1,
        item: "Boissons non alcoolisées",
        activate: false
    },
    {
        id: 2,
        item: "Boissons alcolisées",
        activate: false
    },
    {
        id: 0,
        item: "Nos Vins",
        activate: false
    },
    {
        id: 4,
        item: "Menu bistronomiques",
        activate: false
    }]);
    // useEffect(() => {
    //     axios.get('data.json').then(res => {
    //         setMainElems(res.data.menu);
    //     })
    // }, []);
    let { activeOrder } = useContext(OrderContext);
    const checkUrl = useLocation().state;
    const [tempActivated, setTempActivated] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        let tempArr = [];
        if (checkUrl === null) {
            window.location.href = '/directaccess';
        }
        axios.interceptors.response.use(undefined, (err) => {
            const { config, message } = err;
            if (!config || !config.retry) {
                return Promise.reject(err);
            }
            // retry while Network timeout or Network Error
            if (!(message.includes("timeout") || message.includes("Network Error"))) {
                return Promise.reject(err);
            }
            config.retry -= 1;
            const delayRetryRequest = new Promise((resolve) => {
                setTimeout(() => {
                    console.log("retry the request", config.url);
                    resolve();
                }, config.retryDelay || 1000);
            });
            return delayRetryRequest.then(() => axios(config));
        });
        try {
            //const data = await axios.get('/all-vins.json').then(res => {
            const data = axios.get('https://admin.confluencenamur.be/api/get-data', { retry: 5, retryDelay: 60000 }).then(res => {

                let revisitees = res.data.data.filter(item => {
                    return item.hasOwnProperty("brasserie_revisitees");
                });
                if (revisitees.length > 0) {
                    tempArr.push(mainElems[0]);
                }
                let nonAlcoolisees = res.data.data.filter(item => {
                    return item.hasOwnProperty("boissons_non_alcoolisees");
                });
                if (nonAlcoolisees.length > 0) {
                    tempArr.push(mainElems[1]);
                }
                let alcoolisees = res.data.data.filter(item => {
                    return item.hasOwnProperty("boissons_alcoolisees");
                });
                if (alcoolisees.length > 0) {
                    tempArr.push(mainElems[2]);
                }
                let vins = res.data.data.filter(item => {
                    return item.hasOwnProperty("vins");
                });
                if (vins.length > 0) {
                    tempArr.push(mainElems[3]);
                }
                let bistronomiques = res.data.data.filter(item => {
                    return item.hasOwnProperty("menu_bistronomiques");
                });
                if (bistronomiques.length > 0) {
                    tempArr.push(mainElems[4]);
                }
                setTempActivated([...tempArr]);
                setLoading(false);
            })
        } catch (err) {
            console.log(err);
        }

    }, []);

    window.scrollTo(0, 0);
    return (

        checkUrl !== null && <section className="main-section carte-section">
            <Header title="Notre Carte" target={'/'} />
            {loading && <Loading></Loading>}
            <div className="main-container-flex">

                {/* <div style={{ height: '29.4%' }}> */}

                {
                    tempActivated.map(elem => {
                        return (
                            <div className="menu-element-with-friend" key={elem.id}>
                                <Link className="menu-element-row" to={`/menu/${elem.id}`} state={{}}>
                                    {/* <ImgInfos img={elem.img} infos={{ main: elem.root }} /> */}
                                    {elem.item}
                                </Link>
                            </div>
                        )
                    })
                }
            </div>
            {activeOrder.active && <OrderBtn />}
            <Footer />
        </section>
    )
}
export default Carte;