import React from "react";
import ImgInfos from "../ImgInfos/ImgInfos";
import './event.css';
import Parser from 'html-react-parser';
const Event = (props) => {
    return (
        <div className="event">
            <div className="all-details">
                <div className="img-side">
                    <ImgInfos img={`https://admin.confluencenamur.be/${props.event.image}`} infos={{ main: props.event.title, sub: props.event.date }} />
                </div>
                <div className="text-side">
                    {props.event.description}
                </div>
            </div>
            <div className="sub-details">
                <img src={`https://admin.confluencenamur.be/${props.event.image}`} alt="" />
                <div className="text">
                    <p>
                        {Parser(props.event.body)}
                    </p>
                </div>
            </div>
            <div className="blur">

            </div>
        </div>
    )
}
export default Event;