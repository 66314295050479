import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useGeolocated } from "react-geolocated";
import axios, { Axios } from 'axios';
import './YourOrder.css';
import { OrderContext } from '../../contexts/OrderContext';
import Header from '../Header/Header';
import MenuElement from '../MenuElement/MenuElement';
import MenuItem from '../MenuItem/MenuItem';
import ServiceItem from '../ServiceItem/ServiceItem';
import OrderBtn from '../OrderBtn/OrderBtn';
import { Bottle, EmptyGlass, EditIcon } from '../Svgs/Svgs';
import Cookies from 'js-cookie';
import Loading from '../Loading/Loading';
const YourOrder = (props) => {
    const [mainElems, setMainElems] = useState([]);
    const { activeOrder, handleChange } = useContext(OrderContext);
    const checkUrl = useLocation().state;
    useEffect(() => {
        if (checkUrl === null) {
            window.location.href = '/directaccess';
        }
    }, []);
    const [loading, setLoading] = useState(false);
    let ordersPrice = 0;
    activeOrder.orders.map(elem => {
        if (!elem.removed) { ordersPrice += elem.price; }
    });
    const { coords, isGeolocationAvailable, isGeolocationEnabled } =
        useGeolocated({
            positionOptions: {
                enableHighAccuracy: true,
                maximumAge: 0,
                timeout: Infinity,
            },
            userDecisionTimeout: null,
            geolocationProvider: navigator.geolocation,
            suppressLocationOnMount: false,
            isOptimisticGeolocationEnabled: false,
            onSuccess: (position) => {
                let obj = { ...activeOrder, latitude: position.coords.latitude, longitude: position.coords.longitude };
                handleChange(obj);
            }
        });
    // let saveOrder = () => {
    //     if (activeOrder.active) {
    //         setLoading(true);
    //         let finalObj = {
    //             code: activeOrder.code,
    //             vins: [],
    //             boissons: [],
    //             brasserie_revisitee: [],
    //             menu_bistronomique: [],
    //             latitude: activeOrder.latitude,
    //             longitude: activeOrder.longitude
    //         }
    //         activeOrder.orders.map(order => {
    //             if (order.menuName.toLowerCase().includes('vins') && !order.removed) {
    //                 finalObj.vins = [
    //                     ...finalObj.vins,
    //                     {
    //                         id: order.id,
    //                         glass_count: order.glassCount,
    //                         bottle_count: order.hasOwnProperty('bottleCount') ? order.bottleCount : 0
    //                     }
    //                 ]
    //             } else if (order.menuName.toLowerCase().includes('boissons') && !order.removed) {
    //                 finalObj.boissons = [
    //                     ...finalObj.boissons,
    //                     {
    //                         id: order.id,
    //                         glass_count: order.glassCount
    //                     }
    //                 ]
    //             } else if (order.menuName.toLowerCase().includes('brasserie') && !order.removed) {
    //                 finalObj.brasserie_revisitee = [
    //                     ...finalObj.brasserie_revisitee,
    //                     {
    //                         id: order.id,
    //                         count: order.glassCount
    //                     }
    //                 ]
    //             } else if (order.menuName.toLowerCase().includes('services') && !order.removed) {
    //                 finalObj.menu_bistronomique = [
    //                     ...finalObj.menu_bistronomique,
    //                     {
    //                         type: order.type,
    //                         items: [...order.items.map(item => {
    //                             return {
    //                                 id: item.id,
    //                                 count: item.count
    //                             }
    //                         })]
    //                     }
    //                 ]
    //             }
    //         })
    //         // axios.post('https://admin.confluencenamur.be/api/add-order', finalObj).then(res => {
    //         //     if (res.status === 200) {
    //         //         setLoading(false);
    //         //         let temp = { ...activeOrder };
    //         //         temp = {
    //         //             ...temp,
    //         //             orders: []
    //         //         }
    //         //         handleChange({ ...temp });
    //         //         window.location.href = '/success';
    //         //     }

    //         // }).catch(err => {
    //         //     console.log(err.response.data);
    //         //     setLoading(false);
    //         // });
    //     } else {
    //         window.location.href = '/printfailed';
    //     }
    // }

    window.scrollTo(0, 0);
    return (
        checkUrl !== null && <section className="main-section menu-section">
            {loading && <Loading />}
            <Header title={'Votre Commande'} target="/cart" state={{}} />
            <div className="main-container main-container-your-order">
                <div className="cart-orders">
                    {
                        activeOrder.orders.map(order => {
                            var isBottle = order.hasOwnProperty('bottleCount');
                            // Check if it is service menu or not
                            return order.hasOwnProperty('type') ? (
                                <div key={order.randId}>
                                    <div className="order-side">
                                        <Link to={`/menuedit`} style={order.removed ? { visibility: 'hidden' } : {}} state={{ order: order }}>
                                            <EditIcon />
                                        </Link>
                                        <div className="order-name-cart" style={order.removed ? { 'text-decoration': 'line-through' } : {}} >{order.menuName}
                                            <div className="service-services-cart">{order.hasOwnProperty('items') && order.items.map(item => {
                                                return (<div className="service-services-cart-area" key={item.id}>
                                                    <div className="a-small-margin" style={order.removed ? { 'text-decoration': 'line-through' } : {}} >- </div><div>{item.name}</div>
                                                </div>)
                                            })}</div></div>
                                        <div className="order-quantity-cart" style={order.removed ? { 'text-decoration': 'line-through' } : {}} >(1)</div>
                                        <div className="order-price-cart" style={order.removed ? { 'text-decoration': 'line-through' } : {}} >{order.price}</div>
                                    </div>

                                    <div className='line-between-orders'></div>
                                </div>
                            ) : (
                                <div key={order.randId}>
                                    <div className="order-side">
                                        <Link to={order.hasOwnProperty('bottleCount') ? `/bottle/edit/0/0/0` : `/glass/edit/0/0/0`} style={order.removed ? { visibility: 'hidden' } : {}} state={{ order: order }}>
                                            <EditIcon />
                                        </Link>
                                        <div style={order.removed ? { 'text-decoration': 'line-through' } : {}} className="order-name-cart">{order.elemName}</div>
                                        <div style={order.removed ? { 'text-decoration': 'line-through' } : {}} className="order-quantity-cart">({isBottle ? order.bottleCount : order.glassCount})</div>
                                        <div style={order.removed ? { 'text-decoration': 'line-through' } : {}} className="order-price-cart">{order.price}</div>
                                    </div>
                                    {isBottle && (<div className="order-quantity-number-side" style={order.removed ? { visibility: 'hidden' } : {}}>
                                        <div className="order-quantity-number-side-text">{order.glassCount}</div> <EmptyGlass />
                                    </div>)}
                                    <div className='line-between-orders'></div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>

            {/* {
                        orders.map(order => {
                            return (<div>
                                <div className="order-side">
                                    <Link to={`/bottle/edit/0/0/0`} style={order.is_deleted ? { visibility: 'hidden' } : {}}>
                                        <EditIcon />
                                    </Link>
                                    <div style={order.is_deleted ? { 'text-decoration': 'line-through' } : {}} className="order-name-cart">{order.name}</div>
                                    <div style={order.is_deleted ? { 'text-decoration': 'line-through' } : {}} className="order-quantity-cart">({order.quantity})</div>
                                    <div style={order.is_deleted ? { 'text-decoration': 'line-through' } : {}} className="order-price-cart">{order.quantity * order.price.one}</div>
                                </div>
                                <div className="order-quantity-number-side" style={order.is_deleted ? { visibility: 'hidden' } : {}}>
                                    <div className="order-quantity-number-side-text">{order.quantity}</div> <EmptyGlass />
                                </div>
                                <div className='line-between-orders'></div>
                            </div>)
                        })
                    } */}
            {/* {
                        services.map(service => {
                            return (<div>
                                <div className="order-side">
                                    <EditIcon />
                                    <div className="order-name-cart">{service.name}
                                        <div className="service-services-cart">{service.services.map(one => {
                                            return (<div className="service-services-cart-area">
                                                <div className="a-small-margin">- </div><div>{one.name}</div>
                                            </div>)
                                        })}</div></div>
                                    <div className="order-quantity-cart">(1)</div>
                                    <div className="order-price-cart">{service.price[0]}</div>
                                </div>

                                <div className='line-between-orders'></div>
                            </div>)
                        })
                    } */}


            <Link to={activeOrder.locate ? '/finalsave' : '/save'} className="buy-button-without" state={{}}>
                <div className="buy-button-without-text">Confirmer</div>
                <div className="space">  </div>
                <div className="buy-button-without-price">(€ {ordersPrice})</div>
            </Link>

        </section >
    )
}
export default YourOrder;