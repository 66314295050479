import React, { useState, useEffect, useContext } from 'react';
import { useParams, useLocation, Link } from 'react-router-dom';
import axios from 'axios';
import './MenuServices.css';
import { OrderContext } from '../../contexts/OrderContext';
import Header from '../Header/Header';
import OrderBtn from '../OrderBtn/OrderBtn';
// import $ from 'jquery'

const MenuServices = (props) => {
    let { activeOrder, handleChange } = useContext(OrderContext);
    const checkUrl = useLocation().state;
    useEffect(() => {
        if (checkUrl === null) {
            window.location.href = '/directaccess';
        }
    }, []);
    /** Shared between 2 services and 3 services */
    let { menuType, elem, price } = checkUrl !== null && checkUrl;

    if (checkUrl !== null) price = parseFloat(price.replace(',', '.'));
    let [menuPrice, setMenuPrice] = useState(price);
    const [element, setElement] = useState(checkUrl !== null ? {
        ...elem, sub_menus: elem.sub_menus.map(menu => {
            return {
                ...menu, menu_bistronomique_category: menu.menu_bistronomique_category.map(cat => {
                    return {
                        ...cat,
                        menu_bistronomique: cat.menu_bistronomique.map(target => {
                            return {
                                ...target,
                                selected: false,
                                blocked: false
                            }
                        })
                    }
                })
            }
        })
    } : {});
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    /** Menu 2 Services */
    const [selectOptions, setSelectOptions] = useState([]);
    const [optionSelected, setOptionSelected] = useState(false);
    const multiSelect = 'DESSERT';
    let beforeArr = [];
    let afterArr = [];

    if (menuType === 2 && optionSelected === false) {
        element.sub_menus.map(menu => {
            menu.menu_bistronomique_category.map(cat => {
                beforeArr.push(cat);
            })
        })

        beforeArr.map(ele1 => {
            beforeArr.map(ele2 => {
                if ((beforeArr.indexOf(ele1) !== beforeArr.indexOf(ele2)) && (ele1.id === ele2.id)) {
                    afterArr = [...beforeArr.splice(beforeArr.indexOf(ele2), 1)];
                } else {
                    afterArr = [...beforeArr];
                }
            })
        })
    }
    // Fill two choices buttons with the text and infos
    if (menuType === 2 && selectOptions.length === 0) {
        var temp = [];
        element.sub_menus.map(menu => {
            {
                temp.push({
                    id: menu.id,
                    active: false,
                    text: ''
                });
                menu.menu_bistronomique_category.map(element1 => {
                    temp[element.sub_menus.indexOf(menu)].text = temp[element.sub_menus.indexOf(menu)].text.concat(menu.menu_bistronomique_category.length - 1 !== menu.menu_bistronomique_category.indexOf(element1) ? `${element1.name} et ` : element1.name);

                })
                setSelectOptions(temp);
            }

        })
    }

    // Set element sub_menus to the target menu
    let changeActiveMenu = (subMenuId) => {
        let temp = {
            ...elem, sub_menus: elem.sub_menus.map(menu => {
                return {
                    ...menu, menu_bistronomique_category: menu.menu_bistronomique_category.map(cat => {
                        return {
                            ...cat,
                            menu_bistronomique: cat.menu_bistronomique.map(target => {
                                return {
                                    ...target,
                                    selected: false,
                                    blocked: false
                                }
                            })
                        }
                    })
                }
            })
        }

        temp.sub_menus = temp.sub_menus.filter(target => {
            return target.id === subMenuId;
        });
        setElement({ ...temp });
    }

    /** Menu 2 Services */

    let chooseItem = (mainId, subId, item) => {
        let mainTar = element.sub_menus.filter(tarMen => {
            return tarMen.id === mainId;
        })
        let mainIndex = element.sub_menus.indexOf(mainTar[0]);
        let catTar = element.sub_menus[mainIndex].menu_bistronomique_category.filter(cat => {
            return cat.id === subId;
        })
        let catIndex = element.sub_menus[mainIndex].menu_bistronomique_category.indexOf(catTar[0]);
        const categ = element.sub_menus[mainIndex].menu_bistronomique_category[catIndex].name.toUpperCase();
        if ((((!item.selected) && (!item.blocked)) || (item.blocked)) && (categ !== multiSelect.toUpperCase())) {
            let shallowItem = { ...element };
            shallowItem.sub_menus[mainIndex].menu_bistronomique_category[catIndex].menu_bistronomique = shallowItem.sub_menus[mainIndex].menu_bistronomique_category[catIndex].menu_bistronomique.map(temp => {
                if (temp.id === item.id) {
                    return {
                        ...temp,
                        selected: true,
                        blocked: false
                    }
                } else {
                    return {
                        ...temp,
                        selected: false,
                        blocked: true
                    }
                }

            })
            setElement(shallowItem);
        } else if (categ === multiSelect.toUpperCase()) { // Dessert so I can select more than one choice
            let shallowItem = { ...element };
            if (!item.selected) { // If item not selected select it
                let tempElem = {
                    ...shallowItem.sub_menus[mainIndex].menu_bistronomique_category[catIndex].menu_bistronomique[shallowItem.sub_menus[mainIndex].menu_bistronomique_category[catIndex].menu_bistronomique.indexOf(item)],
                    selected: true,
                    blocked: false
                };
                shallowItem.sub_menus[mainIndex].menu_bistronomique_category[catIndex].menu_bistronomique[shallowItem.sub_menus[mainIndex].menu_bistronomique_category[catIndex].menu_bistronomique.indexOf(item)] = tempElem;
                // Choice a is choosen
                if (shallowItem.sub_menus[mainIndex].menu_bistronomique_category[catIndex].menu_bistronomique.indexOf(tempElem) === 0) {
                    // Choice a is also selected so add 14 to the price
                    if (shallowItem.sub_menus[mainIndex].menu_bistronomique_category[catIndex].menu_bistronomique[1].selected === true) {

                        setMenuPrice(price + 14);

                    } else { // Choice a is not selected so add 4 to the price

                        setMenuPrice(price);

                    }
                }
                // Choice b is choosen
                if (shallowItem.sub_menus[mainIndex].menu_bistronomique_category[catIndex].menu_bistronomique.indexOf(tempElem) === 1) {
                    // Choice a is also selected so add 14 to the price
                    if (shallowItem.sub_menus[mainIndex].menu_bistronomique_category[catIndex].menu_bistronomique[0].selected === true) {

                        setMenuPrice(price + 14);

                    } else { // Choice a is not selected so add 4 to the price

                        setMenuPrice(price + 4);

                    }
                }
            } else { // If item selected unselect it
                let tempElem = {
                    ...shallowItem.sub_menus[mainIndex].menu_bistronomique_category[catIndex].menu_bistronomique[shallowItem.sub_menus[mainIndex].menu_bistronomique_category[catIndex].menu_bistronomique.indexOf(item)],
                    selected: false,
                    blocked: false
                };
                shallowItem.sub_menus[mainIndex].menu_bistronomique_category[catIndex].menu_bistronomique[shallowItem.sub_menus[mainIndex].menu_bistronomique_category[catIndex].menu_bistronomique.indexOf(item)] = tempElem;
                // Choice b is not choosen
                if (shallowItem.sub_menus[mainIndex].menu_bistronomique_category[catIndex].menu_bistronomique.indexOf(tempElem) === 1) {
                    setMenuPrice(price);
                }
                // Choice a is not choosen
                else if (shallowItem.sub_menus[mainIndex].menu_bistronomique_category[catIndex].menu_bistronomique.indexOf(tempElem) === 0) {
                    // Choice b is choosen
                    if (shallowItem.sub_menus[mainIndex].menu_bistronomique_category[catIndex].menu_bistronomique[1].selected) {
                        setMenuPrice(price + 4);
                    }
                    else {
                        setMenuPrice(price);
                    }
                }
            }
            setElement(shallowItem);
        }
    }
    let checkSelected = (item) => {
        return item.selected;
    }
    let checkBlocked = (item) => {
        return item.blocked;
    }
    let getActiveSelected = () => {
        return selectOptions.map(option => {
            if (option.active) {
                return option.text;
            }
        })
    }
    let addOrder = () => {
        if (activeOrder.active) {
            var temp = { ...activeOrder };
            var resObj = {
                type: parseInt(element.sub_menus[0].name.charAt(element.sub_menus[0].name.length - 1)),
                items: [],
                price: menuPrice,
                removed: false,
                menuName: element.name,
                randId: Math.random(),
                allMenu: elem,
                mainPrice: price,
                optionText: menuType === 2 ? getActiveSelected() : ''
            }
            element.sub_menus.map(menu => {

                menu.menu_bistronomique_category.map(menuCat => {
                    menuCat.menu_bistronomique.map(elem => {
                        if (elem.selected) {
                            resObj = {
                                ...resObj,
                                items: [
                                    ...resObj.items,
                                    {
                                        id: elem.id,
                                        categoryName: menuCat.name,
                                        name: elem.title,
                                        count: 1
                                    }
                                ]
                            }
                        }

                    })
                })
            })
            temp = { ...activeOrder, orders: [...temp.orders, resObj] };
            resObj.items.length > 0 && handleChange(temp);
            let tempArr = {
                ...element, sub_menus: element.sub_menus.map(menu => {
                    return {
                        ...menu, menu_bistronomique_category: menu.menu_bistronomique_category.map(cat => {
                            return {
                                ...cat,
                                menu_bistronomique: cat.menu_bistronomique.map(target => {
                                    return {
                                        ...target,
                                        selected: false,
                                        blocked: false
                                    }
                                })
                            }
                        })
                    }
                })
            }
            setElement(tempArr);
            setMenuPrice(price);
        }
    }

    return (
        checkUrl !== null && <section className="main-section menu-section">
            <Header title={element.name} target="/menu/4" state={{}} />
            <div className="main-container">

                {   // Set Button Selected
                    menuType === 2 ? <div className="select-area">
                        {selectOptions.map(element => {
                            return (
                                <div key={element.id} className={element.active ? "option-selected" : "select-option"} onClick={() => {
                                    let temp = [...selectOptions];
                                    for (let i = 0; i < temp.length; i++) {
                                        temp[i].active = false
                                    }
                                    temp[temp.indexOf(element)] = {
                                        ...element,
                                        active: true
                                    }
                                    setOptionSelected(true);
                                    setSelectOptions(temp);
                                    // Show the menu if it matches the choice
                                    changeActiveMenu(element.id);
                                }}>{element.text.toLowerCase()} </div>
                            )
                        })}
                    </div> : ''
                }
                {
                    (menuType === 2 && optionSelected === false) && (

                        <React.Fragment>
                            {
                                afterArr.map(cat => (
                                    <div className="selectable-services" key={cat.id}>
                                        <div className="selectable-service-name">{cat.name}</div>
                                        {
                                            cat.menu_bistronomique.map(item => (
                                                <div key={item.id} className="selectable-service-elems" style={{ marginBottom: cat.description && cat.menu_bistronomique.indexOf(item) === cat.menu_bistronomique.length - 1 ? '0px' : '5px' }}>
                                                    <div className="selectable-service-elem-name">
                                                        <div className="non-selectable-service-elem-EnnerName">
                                                            {item.title}
                                                        </div>
                                                    </div>
                                                    {/* <div className="selectable-service-elem-description"></div> */}
                                                </div>
                                            )
                                            )
                                        }
                                        {cat.description && <p className="description">
                                            {cat.description !== "''" && cat.description}
                                        </p>}
                                    </div>
                                )
                                )


                            }
                        </React.Fragment>
                    )
                }
                {(menuType === 3 || (menuType === 2 && optionSelected === true)) && (
                    element.sub_menus.map(menu => (
                        menu.menu_bistronomique_category.map(item => (
                            <div className="non-selectable-services" key={item.id}>
                                <div className="non-selectable-service-name">{item.name.toUpperCase()}</div>
                                <div className="non-selectable-service-elems">
                                    {
                                        item.menu_bistronomique.map(sub => (
                                            <div key={sub.id} className={checkBlocked(sub) ? "non-selectable-service-elem-name blocked" : checkSelected(sub) ? "non-selectable-service-elem-name selected" : "non-selectable-service-elem-name"} style={{ marginBottom: item.description && item.menu_bistronomique.indexOf(sub) === item.menu_bistronomique.length - 1 ? '0px' : '5px', cursor: !activeOrder.active && "auto", border: !activeOrder.active && '0px' }} onClick={() => { if (activeOrder.active) { chooseItem(menu.id, item.id, sub) } }}>
                                                <div className="non-selectable-service-elem-EnnerName">{sub.title}</div></div>
                                        ))}
                                    <p className="description">
                                        {item.description !== "''" && item.description}
                                    </p>
                                </div>

                            </div>
                        ))
                    ))
                )

                }
                {((menuType === 3 || (menuType === 2 && optionSelected === true)) && activeOrder.active) && (
                    <Link to={"/menu/4"} state={{}} className="buy-button-without" onClick={() => addOrder()}>
                        <div className="buy-button-without-text">Ajouter  1</div>
                        <div className="space">  </div>
                        <div className="buy-button-without-price">(€{menuPrice})</div></Link>
                )
                }
            </div>

        </section >
    )
}
export default MenuServices;