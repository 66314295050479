import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Header from '../Header/Header';
import Producteur from "../Producteur/Producteur";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/navigation";
import "./producteurs.css";

// import required modules
import { Grid, Navigation } from "swiper";
import Img0 from '../../images/Boucherie.jpg';
import Img1 from '../../images/Fromagerie-du-Samson.jpg';
import Img2 from '../../images/Beole.jpg';
import Img3 from '../../images/Bossi.jpg';
import Img4 from '../../images/Longlune.jpg';
import Img5 from '../../images/fosFleuri.jpg';
import Img6 from '../../images/Ry d\'Argent.jpg';
import Img7 from '../../images/Jardins de wepion.jpg';
import Img8 from '../../images/Au Grand Bleu.jpeg';
import Img9 from '../../images/Bertinchamps.jpg';
import Img10 from '../../images/Brasserie Namur.jpg';
import Img11 from '../../images/Clocher.jpg';
import Img12 from '../../images/La_Mu.jpg';
import { OrderContext } from "../../contexts/OrderContext";
import Footer from "../Footer/Footer";
import Loading from "../Loading/Loading";
const Producteurs = (props) => {
    const { activeOrder } = useContext(OrderContext);
    const [producteurs, setProducteurs] = useState([
        {
            id: 0,
            img: Img0,
            firstName: 'Sébastien, Benjamin & Manuel',
            lastName: '',
            job: 'Boucherie Originelle',
            desc: `Notre "Boucherie Originelle" est une boucherie artisanale , 100% local 
            Viande exclusivement produite dans nos campagnes et préparations maison en fonction des saisons...
            -	Les viandes présentées sont originaires de nos fermes, de notre terroir.
            -	Les animaux sont nourris avec un maximum de fourrages produits localememt (céréales, luzernes, prairies,…) et sans OGM.
            -	Notre coopérative permet à ses éleveurs d'obtenir une juste rémunération de leur travail mais également un prix abordable pour nos consom'acteurs ! 
            -	Retour sur un produit qui provient directement de la ferme vers l’abattoir revenant aux mains de David, notre artisan boucher/ charcutier sans d'autres intermédiaires. 
            -	Un retour aux saveurs d’Antan pour tous nos produits.
            
            Site web : http://boucherie-originelle.be
            `
        },
        {
            id: 1,
            img: Img1,
            firstName: 'Vincent Verleyen',
            lastName: '',
            job: 'Fromagerie du Samson',
            desc: `Premier Fromager de Belgique en 2014. 
            La Fromagerie du Samson est à la fois crèmerie et fromagerie.  Vincent Verleyen fabrique sur place entre 30 et 40  fromages maison au lait cru.
            Savoir-faire, authenticité, tradition et importance du geste  sont les maître-mots. Ils sont aussi à l’origine de la reconnaissance officielle récente du statut d’artisan.
            Des charcuteries d’origine belge, française, espagnole et corse, du pain, du vin, des produits locaux d’épicerie fine sont également proposés.
            
            Site web : ttps://www.fromageriedusamson.com/             
            `
        },
        {
            id: 2,
            img: Img2,
            firstName: 'Ferme de la Beole',
            lastName: '',
            job: '',
            desc: `La ferme de la Béole vous propose une déclinaison de beurres uniques, des plus classiques aux plus originaux, qui feront frémir vos papilles.                                                                            Les beurres sont produits de A à Z par la ferme, de la gestion du troupeau à la vente du beurre, tout est fait à la Béole ! 								         Si vous avez envie de nouveauté dans vos plats, de parfumer vos viandes, pommes de terre ou pâtes avec un petit quelque chose de spécial ou encore d’accompagner vos barbecues, vous êtes au bon endroit !									         « Notre objectif est de préserver une agriculture familiale, respectueuse de l’environnement et des animaux en instaurant une relation de confiance avec nos clients. »
            Site web : https://www.fermedelabeole.be
            `
        },
        {
            id: 3,
            img: Img3,
            firstName: 'Ludovic',
            lastName: '',
            job: 'Bossimé Ferme Gastronomique',
            desc: `Bossimé, un projet durable global porté par un Chef, Ludovic Vanackere, et son équipe avec un objectif commun : faire vivre la Gastronomie raisonnée et l'économie locale à chaque instant au restaurant comme à la maison.
            Au menu : produits locaux et de saison issus du circuit-court et durable, objectif 0 déchet, développement de l'emploi local, transmission de valeurs sociales et écoresponsables.
            Site Web : https://www.bossime.be                        
            `
        },
        {
            id: 4,
            img: Img4,
            firstName: 'Stéphane Longlune',
            lastName: '',
            job: '',
            desc: `Stéphane Longlune est un cultivateur ayant fait le choix d’une agriculture raisonnée . Il propose plusieurs sortes de fruits et légumes : asperges, fraises, carottes, salades de blé, pommes de terre, blettes, fenouils, betteraves rouges, poireaux, choux…
            Dernier d’une lignée de cultivateurs depuis plus de 5 générations , il est installé à Jurbise depuis près de 15 ans.
            Pour préserver la richesse de son terroir, Stéphane prône le respect de la nature et n’utilise que des pesticides biologiques et des engrais naturels (compost et fumier) provenant exclusivement d’une ferme voisine et pouvant être employés en agriculture bio.
            `
        },
        {
            id: 5,
            img: Img5,
            firstName: 'Martine',
            lastName: '',
            job: 'Le Fossé Fleuri',
            desc: `C’est un besoin d’authenticité et de spontanéité tant envers les autres qu’envers moi même, qui m’a poussée à faire ce pas audacieux que je n’aurais jamais imaginé faire, ouvrir « ma » maison de thés, d’infusions et de chocolat « Bean to bar »,

            Le Fossé Fleuri est une boutique de thés , d’infusions et de chocolats « Bean to bar ». Même si tout les produits présents au Fossé Fleuri ne sont pas labélisés « Bio » ils proviennent tous de démarches eco-citoyennes. Mes différents voyages me permettent de rencontrer de petits producteurs, des artisans, des créateurs.. et je m’entoure de collaborateurs ayant les même valeurs que moi.
            
            Site web : https://lefossefleuri.be/le-fosse-fleuri/
            `
        },
        {
            id: 6,
            img: Img6,
            firstName: 'Jean François ',
            lastName: '',
            job: 'Domaine du Ry D’argent',
            desc: `La famille Baele a exploité une ferme de culture et de bovins laitiers depuis des générations dans la région namuroise. Les différentes crises rencontrées ces dernières années dans le secteur agricole ont poussé Jean-François, le jeune fils passionné d’agriculture, à envisager une nouvelle orientation de l’exploitation familiale. L’envie de diversifier ses activités lui est clairement apparue en 2005, quand il a imaginé l’implantation d’un vignoble de 5 hectares en pleine Hesbaye.
            Jean-François Baele n’envisageait pas seulement de produire du vin rouge et du vin blanc, il voulait surtout élaborer des vins « nouveau style » : des vins poivrés, épicés etc. Pour ce faire, et obtenir des vins de qualité Jean-François a investi dans du matériel performant qui lui permet, dès à présent, d’exploiter ses nouveaux cépages. A l’avenir ce matériel lui permettra d’agrandir son domaine et d’étoffer sa gamme de vin.
            
            Site web : https://www.domainedurydargent.com
            `
        },
        {
            id: 7,
            img: Img7,
            firstName: 'Florent',
            lastName: '',
            job: 'Les Jardins de Wépion',
            desc: `Florent, jeune entrepreneur wépionnais perpétue la tradition familiale depuis plusieurs générations déjà de cultiver et vendre les véritables Fraises de Wépion. 

            N’hésitez pas à le suivre sur les réseaux sociaux pour trouver tous les points de ventes et produits dérivés à base de ces fraises. Un véritable délice pour vos papilles. 
            `
        },
        {
            id: 8,
            img: Img8,
            firstName: 'Au Grand Bleu',
            lastName: '',
            job: '',
            desc: `Au Grand Bleur propose une gamme très complète de produits de la mer et d’eau douce. Grossiste et détaillant, nous sommes en mesure de vous proposer une fraicheur irréprochable grâce au volume important qui transite quotidiennement au sein de nos ateliers. Nous offrons également une traçabilité complète et pointilleuse de l’ensemble de nos produits.

            Au Grand Bleu favorise les produits issus de la pêche durable et responsable. Nous avons notamment signé la charte « North Sea Chefs ». Nous nous engageons à mettre en avant des poisons méconnus ou mal-aimés, issus de nos côtes, et donc présents en plus grandes quantités.
            
            Site web : https://www.augrandbleu.com
            `
        },
        {
            id: 9,
            img: Img9,
            firstName: 'Brasserie Bertinchamps',
            lastName: '',
            job: '',
            desc: `Notre famille était à la recherche d’un site de caractère pour y développer une nouvelle brasserie et ainsi continuer la longue liste des bières déjà brassées par notre père, Benoît Humblet.

            En 2011 nous découvrons à proximité de Gembloux, la vieille Ferme en carré de Bertinchamps sise au milieu des champs dans un superbe environnement, mais dans un piteux état. La ferme nécessite une rénovation totale, nous démarrons donc directement les travaux en mettant souvent nous-même les mains dans le béton.
            
            Puis en mars 2013 notre installation brassicole ultra moderne d’une capacité annuelle de 6.000 hl est placée dans les vieilles granges, contraste saisissant entre la pointe de la technologie et un site de plus de 7 siècles.
            
            Prise à Bertinchamps, où le hasard nous a amené après le Zaïre, la Centrafrique, le Cameroun, Bruxelles, Strasbourg, Val-Dieu.. 
            Toute la famille se lance dans ce projet fou : créer et développer de nouvelles bières de ferme, mais également restaurer un gigantesque site à l’abandon.
            
            Site web : https://www.bertinchamps.be/
            `
        },
        {
            id: 10,
            img: Img10,
            firstName: 'Brasserie de Namur',
            lastName: '',
            job: '',
            desc: `Xavier, François, Bertrand, Alexandre et Laurent. L’ordre importe peu. Cinq Namurois, cinq parcours. Cinq épicuriens en recherche d’un challenge, et surtout cinq personnalités qui ont dès le début posé les bases d’un projet ambitieux.

            Partageant tous un attachement à leur ville, au design et aux produits artisanaux de caractère, ils décident de créer à Namur une bière présentant une forte identité et un goût qu’ils n’avaient pas encore trouvé dans une bière spéciale.
            
            Le succès de la Houppe a accéléré les choses et leur a permis de construire progressivement un véritable projet de Brasserie, faisant renouer Namur avec son glorieux passé brassicole. La Brasserie de Namur est maintenant installée au pied de la Citadelle, à deux pas du centre-ville.
            
            Site web : https://www.houppe.be/fr/
            `
        },
        {
            id: 11,
            img: Img11,
            firstName: 'Brasserie du Clocher',
            lastName: '',
            job: '',
            desc: `« Nous voulons promouvoir la bière comme une boisson noble, au même titre que le vin ou le champagne.
            Lui rendre ses lettres de noblesse et apprendre à ceux qui le souhaitent à mieux l’appréhender et à en savourer pleinement la richesse et la variété »
            
            La Brasserie, un projet passion, un projet participatif
            
            « Dans un an, on aura brassé à Malonne ! » : le pari fou lancé fin 2014 par Jean et Alex est devenu réalité. En quelques mois, la chapelle du Piroy sur les hauteurs de Malonne est devenue une brasserie.
            
            Site web : https://brasserieduclocher.be/philomene/
            `
        },
        {
            id: 12,
            img: Img12,
            firstName: 'La Manufacture Urbaine',
            lastName: '',
            job: '',
            desc: `LaM•U c’est au départ une volonté et une ambition communes portées par les deux fondateurs, Olivier Genin et Jurgen Dewjin qui se connaissent depuis plus de 20 ans et sont associés depuis 15 ans dans de précédentes aventures entrepreneuriales.

            LaM•U c’est aussi une formidable envie de contribuer concrètement au changement (Orbis Novare) en proposant un concept inédit et audacieux autour des bières et du café qui honore leurs valeurs, replace l’homme au centre du travail et la valorisation de l’artisanat régional au cœur de leurs actions. LaM•U c’est enfin l’envie de s’inscrire et de participer au renouveau économique et éthique opéré par la ville de Charleroi depuis quelques années.
            
            Site web : https://www.lam-u.com
            
            `
        },
    ]);
    let [loaded, setLoaded] = useState(false);
    let [data, setData] = useState([]);
    const checkUrl = useLocation().state;
    useEffect(() => {
        if (checkUrl === null) {
            window.location.href = '/directaccess';
        }
    }, []);
    useEffect(() => {
        axios.interceptors.response.use(undefined, (err) => {
            const { config, message } = err;
            if (!config || !config.retry) {
                return Promise.reject(err);
            }
            // retry while Network timeout or Network Error
            if (!(message.includes("timeout") || message.includes("Network Error"))) {
                return Promise.reject(err);
            }
            config.retry -= 1;
            const delayRetryRequest = new Promise((resolve) => {
                setTimeout(() => {
                    console.log("retry the request", config.url);
                    resolve();
                }, config.retryDelay || 1000);
            });
            return delayRetryRequest.then(() => axios(config));
        });
        try {
            axios.get('https://admin.confluencenamur.be/api/get-data', { retry: 5, retryDelay: 60000 }).then(res => {
                let produc = res.data.data.filter(item => {
                    return item.hasOwnProperty("producteurs");
                })[0].producteurs;
                let tempData = [...data];
                tempData = [...produc];
                setData(tempData);
                setLoaded(true);
            });
        }
        catch (err) {
            console.log(err);
        }
    }, []);
    window.scrollTo(0, 0);
    return (
        checkUrl !== null && <div className="main-section producteurs-section">
            {!loaded && <Loading></Loading>}
            <Header title='Nos producteurs' target={'/'} />
            <div className="main-container">
                <div className="producteurs">
                    <Swiper
                        spaceBetween={0}
                        slidesPerView={3}
                        speed={1500}
                        direction="horizontal"
                        grid={{
                            rows: 2
                        }}
                        navigation={
                            {
                                nextEl: '.review-swiper-button-next',
                                prevEl: '.review-swiper-button-prev',
                            }}
                        breakpoints={{
                            1: {
                                slidesPerView: 'auto',
                                direction: "vertical",
                                allowTouchMove: true,
                                grid: {
                                    rows: 2
                                }
                            },
                            577: {
                                direction: 'horizontal',
                                slidesPerView: 2,
                                grid: {
                                    rows: 2
                                }
                            },
                            1024: {
                                slidesPerView: 4,
                                direction: "horizontal",
                                grid: {
                                    rows: 2
                                }
                            }
                        }}
                        modules={[Grid, Navigation]}
                        className="producteurs-swiper"
                    >

                        {
                            data.map(producteur => {
                                return (
                                    <SwiperSlide key={producteur.id} className='producteur'>
                                        <Producteur producteur={producteur} state={{}} />
                                    </SwiperSlide>
                                )
                            })
                        }
                        <div className="review-swiper-button-prev center-con">

                            <div className="round">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>

                        </div>
                        <div className="review-swiper-button-next center-con">

                            <div className="round">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>

                        </div>
                    </Swiper>
                </div>
            </div>
            <Footer />
        </div >
    );
}
export default Producteurs;
