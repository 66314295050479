import React, { useContext, useEffect } from "react";
import { OrderContext } from '../../contexts/OrderContext';
import { useGeolocated } from "react-geolocated";
import { useLocation } from 'react-router-dom';
import axios from "axios";
import Loading from "../Loading/Loading";
const FinalSave = (props) => {
    const checkUrl = useLocation().state;
    useEffect(() => {
        if (checkUrl === null) {
            window.location.href = '/directaccess';
        }
    }, []);
    const { activeOrder, handleChange } = useContext(OrderContext);
    const clearCacheData = () => {
        caches.keys().then((names) => {
            names.forEach((name) => {
                caches.delete(name);
            });
        });
    };
    useEffect(() => {
        clearCacheData();
    }, []);
    const { coords, isGeolocationAvailable, isGeolocationEnabled } =
        useGeolocated({
            positionOptions: {
                enableHighAccuracy: true,
                maximumAge: 0,
                timeout: Infinity,
            },
            userDecisionTimeout: null,
            geolocationProvider: navigator.geolocation,
            suppressLocationOnMount: false,
            isOptimisticGeolocationEnabled: false,
            onSuccess: (position) => {
                if (activeOrder.active) {
                    let finalObj = {
                        code: activeOrder.code,
                        vins: [],
                        boissons_non_alcoolisees: [],
                        boissons_alcoolisees: [],
                        brasserie_revisitee: [],
                        menu_bistronomique: [],
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude
                    }
                    activeOrder.orders.map(order => {
                        if (order.menuName.toLowerCase().includes('vins') && !order.removed) {
                            finalObj.vins = [
                                ...finalObj.vins,
                                {
                                    id: order.id,
                                    glass_count: order.glassCount,
                                    bottle_count: order.hasOwnProperty('bottleCount') ? order.bottleCount : 0
                                }
                            ]
                        } else if (order.menuName.toLowerCase().includes('boissons non alcoolisées') && !order.removed) {
                            finalObj.boissons_non_alcoolisees = [
                                ...finalObj.boissons_non_alcoolisees,
                                {
                                    id: order.id,
                                    glass_count: order.glassCount
                                }
                            ]
                        }
                        else if (order.menuName.toLowerCase().includes('boissons alcolisées') && !order.removed) {
                            finalObj.boissons_alcoolisees = [
                                ...finalObj.boissons_alcoolisees,
                                {
                                    id: order.id,
                                    glass_count: order.glassCount
                                }
                            ]
                        } else if (order.menuName.toLowerCase().includes('grignoter') && !order.removed) {
                            finalObj.brasserie_revisitee = [
                                ...finalObj.brasserie_revisitee,
                                {
                                    id: order.id,
                                    count: order.glassCount
                                }
                            ]
                        } else if (order.menuName.toLowerCase().includes('services') && !order.removed) {
                            finalObj.menu_bistronomique = [
                                ...finalObj.menu_bistronomique,
                                {
                                    type: order.type,
                                    items: [...order.items.map(item => {
                                        return {
                                            id: item.id,
                                            count: item.count
                                        }
                                    })]
                                }
                            ]
                        }
                    });
                    axios.interceptors.response.use(undefined, (err) => {
                        const { config, message } = err;
                        if (!config || !config.retry) {
                            return Promise.reject(err);
                        }
                        // retry while Network timeout or Network Error
                        if (!(message.includes("timeout") || message.includes("Network Error"))) {
                            return Promise.reject(err);
                        }
                        config.retry -= 1;
                        const delayRetryRequest = new Promise((resolve) => {
                            setTimeout(() => {
                                console.log("retry the request", config.url);
                                resolve();
                            }, config.retryDelay || 1000);
                        });
                        return delayRetryRequest.then(() => axios(config));
                    });
                    axios.post('https://admin.confluencenamur.be/api/add-order', finalObj, { retry: 5, retryDelay: 60000 }).then(res => {
                        if (res.status === 200) {
                            let temp = { ...activeOrder };
                            temp = {
                                ...temp,
                                orders: []
                            }
                            handleChange({ ...temp });
                            // console.log(finalObj);
                            window.location.href = '/success';
                        }

                    }).catch(err => {
                        console.log(err.response.data);
                        //  console.log(finalObj);
                        window.history.back();
                    });
                } else {
                    window.location.href = '/printfailed';
                }
            }
        });
    return (
        <Loading></Loading>
    )
}
export default FinalSave;