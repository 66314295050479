import React, { useContext, useEffect } from "react";
import { OrderContext } from '../../contexts/OrderContext';
import { useLocation } from 'react-router-dom';
import axios from "axios";
import Loading from "../Loading/Loading";
const Save = (props) => {
    const checkUrl = useLocation().state;
    useEffect(() => {
        if (checkUrl === null) {
            window.location.href = '/directaccess';
        }
        if (activeOrder.active) {
            let finalObj = {
                ...activeOrder,
                code: activeOrder.code,
                vins: [],
                boissons_non_alcoolisees: [],
                boissons_alcoolisees: [],
                brasserie_revisitee: [],
                menu_bistronomique: []
            }
            activeOrder.orders.map(order => {
                if (order.menuName.toLowerCase().includes('vins') && !order.removed) {
                    finalObj.vins = [
                        ...finalObj.vins,
                        {
                            id: order.id,
                            glass_count: order.glassCount,
                            bottle_count: order.hasOwnProperty('bottleCount') ? order.bottleCount : 0
                        }
                    ]
                } else if (order.menuName.toLowerCase().includes('boissons non alcoolisées') && !order.removed) {
                    finalObj.boissons_non_alcoolisees = [
                        ...finalObj.boissons_non_alcoolisees,
                        {
                            id: order.id,
                            glass_count: order.glassCount
                        }
                    ]
                }
                else if (order.menuName.toLowerCase().includes('boissons alcolisées') && !order.removed) {
                    finalObj.boissons_alcoolisees = [
                        ...finalObj.boissons_alcoolisees,
                        {
                            id: order.id,
                            glass_count: order.glassCount
                        }
                    ]
                } else if (order.menuName.toLowerCase().includes('grignoter') && !order.removed) {
                    finalObj.brasserie_revisitee = [
                        ...finalObj.brasserie_revisitee,
                        {
                            id: order.id,
                            count: order.glassCount
                        }
                    ]
                } else if (order.menuName.toLowerCase().includes('services') && !order.removed) {
                    finalObj.menu_bistronomique = [
                        ...finalObj.menu_bistronomique,
                        {
                            type: order.type,
                            items: [...order.items.map(item => {
                                return {
                                    id: item.id,
                                    count: item.count
                                }
                            })]
                        }
                    ]
                }
            });
            axios.interceptors.response.use(undefined, (err) => {
                const { config, message } = err;
                if (!config || !config.retry) {
                    return Promise.reject(err);
                }
                // retry while Network timeout or Network Error
                if (!(message.includes("timeout") || message.includes("Network Error"))) {
                    return Promise.reject(err);
                }
                config.retry -= 1;
                const delayRetryRequest = new Promise((resolve) => {
                    setTimeout(() => {
                        console.log("retry the request", config.url);
                        resolve();
                    }, config.retryDelay || 1000);
                });
                return delayRetryRequest.then(() => axios(config));
            });
            // window.location.href = '/success';
            axios.post('https://admin.confluencenamur.be/api/add-order', finalObj, { retry: 5, retryDelay: 60000 }).then(res => {
                if ((res.status === 200) || (res.data.success === false && res.data.error === "Table isn't available" && res.data.busy === 1)) {
                    let temp = { ...activeOrder };
                    temp = {
                        ...temp,
                        orders: []
                    }
                    handleChange({ ...temp });
                    console.log(res);
                    window.location.href = '/success';
                }

            }).catch(err => {
                console.log(err.response.data);
                window.history.back();
            });
        } else {
            window.location.href = '/printfailed';
        }
    }, []);
    const { activeOrder, handleChange } = useContext(OrderContext);
    const clearCacheData = () => {
        caches.keys().then((names) => {
            names.forEach((name) => {
                caches.delete(name);
            });
        });
    };
    useEffect(() => {
        clearCacheData();
    }, []);

    return (
        <Loading></Loading>
    )
}
export default Save;