import react, { useEffect, useState, useContext } from 'react';
import { useParams } from "react-router-dom";
import { OrderContext } from "../../contexts/OrderContext";
import Loading from '../Loading/Loading';
const CheckCode = () => {
    let { activeOrder, handleChange } = useContext(OrderContext);
    const { code } = useParams();
    let [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (code.charAt(0) === '0') {
            localStorage.clear();
            let temp = { ...activeOrder, active: true, code: code, orders: [], expire: new Date().getTime() + 1800000, locate: false };
            handleChange(temp);
            setIsLoading(false);
            window.location.href = `/`;

        } else if (code.charAt(0) === '1') {
            localStorage.clear();
            let temp = { ...activeOrder, locate: true };
            handleChange(temp);
            setIsLoading(false);
            window.location.href = `/checkposition/code=${code}`;
        }
    }, []);
    return (
        <section className="main-section position-section">
            {isLoading && <Loading />}
        </section >
    )
}
export default CheckCode;